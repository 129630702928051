@import "../_variables";
.Vendors {
  display:grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  @media(max-width:768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.2rem;
  }
  @media(max-width:512px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
  }
  .location {
    text-align:left;
    h3 {
      grid-column: 1/4;
    }
  }
  .vendor {
    display: block;
    padding:1rem;
    background-color:$lightGreen;
    //color:white;
    margin:1rem 0;
    border-radius: 10px;
    border:2px solid $lightGreen;
    transition:border 0.3s;
    &:hover {
      border:2px solid $green;
      box-shadow:0px 4px 4px transparentize($green,0.7);
    }
  }
  .title {
    font-weight:bold;
  }
}