@import "../_variables";

.Products {
  display:block;
  .productList {
    display:grid;
    margin:2rem 0;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(4, 1fr);
    @media(max-width:768px) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1.2rem;
    }
    @media(max-width:512px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
    }


    /* Dealing with 2 orphan items */
    > * {
      &:last-child:nth-child(1) {
        grid-column-end: 4;
        border:4px solid gold;
      }
      &:nth-last-child(2):nth-child(3n + 1) {
        grid-column-end: 4;
      }

      /* Dealing with single orphan */
      &:last-child:nth-child(3n - 2) {
        grid-column-end: 5;
      }
    }

    .image {
      background-size:contain;
      background-position: center;
      background-repeat:no-repeat;
      border-radius: 80px;
      height:120px;
      width:120px;
      margin:0 auto;
      @media(max-width:768px) {
        height:85px;
        width:85px;
      }
      @media(max-width:512px) {
        height:65px;
        width:65px;
      }

    }
    h4 {
      font-weight:normal;
      font-size:16px;
      margin:0.5rem 0;
      line-height:initial;
      color:$darkGray;
      @media(max-width:768px) {
        font-size:14px;
      }
      @media(max-width:512px) {
        font-size:14px;
      }

    }
  }
}