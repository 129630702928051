@import "./_variables";
.error {
  color: white;
  margin: 2rem;

  pre {
    white-space: pre-wrap;
    padding: 1rem;
  }
}
.logo {
  width:160px;
  height:160px;
  display:block;
  background-image:url("/static/logo-320.png");
  background-size:contain;
  margin:0 auto;
}
.content {
  display: block;
  text-align:center;
  //background-color: #f3f3f3;
  //box-shadow: 2px 2px 2px rgba(68, 68, 68, 0.3);
  //padding: 1rem;
  //margin: 1rem 0;
  min-height:60vh;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;
  a {
    color:$green;
    text-decoration:none;
  }
  font-size:16px;
  line-height:1.4rem;
  margin:0;

}
.main {
  width: 90%;
  max-width:1280px;
  margin: auto;
}

.header {
  display: block;
}

hr {
  border-top:2px solid $green;
  outline: none;
  background:none;
  height:0px;
  margin:1.5rem 0;
}

.menu {
  margin: 1rem 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
  width:100%;
  text-align:center;
  font-size:18px;

  @media(max-width:768px) {
    font-size:16px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media(max-width:512px) {
    font-size:16px;
    grid-template-columns: repeat(2, 1fr);
  }

  a {
    padding:8px 0px;
    border-radius:5px;
    transition:all 0.3s;
  }
  a:hover {
    color:white;
    background:$green;
  }
}
.footer {
  display:block;
  width:100%;
  text-align:center;
  background-color:$lightGreen;
  //box-shadow:0 -3px 8px transparentize($green,0.5);
  border-top:1px solid transparentize($green,0.7);
  padding:1rem;
  font-size:13px;
  box-sizing: border-box;
  color:$gray;
  margin-top:2rem;
}
