@import "../_variables";
.Home {
  iframe {
    width:100%;
    max-width:700px;
    height:60vw;
    max-height:400px;
    margin:1rem auto;
    border-radius: 10px;
  }
  .features {
    display:grid;
    margin:2rem 0;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    width:100%;
    text-align:center;
    h3 {
      color:$darkGray;
    }
    p {
      color:$gray;
    }
    @media(max-width:768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media(max-width:512px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.icon {
  color:$green;
  font-size:2rem;
  padding:1rem;
  border:3px solid $green;
  border-radius:50px;
  display:inline-block;
}
.subText {
  width:80%;
  margin: 3rem auto 4rem;
  max-width:870px;
}